import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../img/foto.png"

export const Banner = () => {
    const [loopNum, setloopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["Web developer", "UI/UX Designer" ];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300- Math.random() * 50);
    const period = 1500;

    useEffect(() => {
        let ticker= setInterval(()=>{
            tick();

        },delta)

        return () => { clearInterval(ticker)};
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length -1) : fullText.substring(0, text.length +1);

        setText(updatedText);

        if(isDeleting) {
            setDelta(prevDelta => prevDelta /2)
        }

        if(!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        }else if(isDeleting && updatedText === ''){
            setIsDeleting(false);
            setloopNum(loopNum + 1);
            setDelta(500);
        }
    }

    
    return (
        <section className="banner" id="home"> 
            <Container>
                <Row className="align-itens-center">
                    <Col xs={12} md={6} xl={7}>
                        <span className="tagline">Welcome to my Portfolio</span>
                        <h1>{"Hi, I'm Leonardo Granado "}</h1> 
                        <h1><span className="wrap">{text}</span></h1>
                        <p>Graduating as Technologist in System Analysis and Development focused in front-end development, user interface and user experience.</p>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <img src={headerImg} alt="header img"/>

                    </Col>
                </Row>

            </Container>
        </section>
    )
}