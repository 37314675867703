import { Container, Row, Col } from "react-bootstrap";
import logo from "../img/logo.png";
import navIcon1 from '../img/navIcon1.png';
import navIcon2 from '../img/navIcon2.png';
import navIcon3 from '../img/navIcon3.png';

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-item-center">
                    <Col sm={6}>
                        <img className="logo-foot" src={logo} alt="logo"/>
                    </Col>
                    <Col sm={6} className="text-center text-sm-end">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/in/leonardo-granado-a735b319b/"><img src={navIcon1}/></a>
                            <a href="https://instagram.com/granadosoy"><img src={navIcon2}/></a>
                            <a href="https://github.com/LeoGranado"><img src={navIcon3}/></a>
                        </div>
                        <p>CopyRight 2022. All Right Reserved</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}